@import '../../assets/styles/colors';
.DashSideForm {
    background-color: $primary-bright;
    padding: 2rem;
    border-radius: 0.5rem;
    position: sticky;
    top: 2rem;
    .Button {
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}