@import '../../assets/styles/colors';
.AdminSettings {
	form {
		text-align: left;
		padding-bottom: 2rem;
		margin: 2rem 0;
		border-bottom: solid 1px $gray-bright;
		h3 {
			margin-bottom: 1.5rem;
		}
		.row {
			margin-bottom: 1rem;
			label {
				margin-bottom: 0;
			}
		}
	}
}
