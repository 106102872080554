@import '../../assets/styles/colors';
.Hero {
	background-size: cover;
	background-position: center;
	&.large {
		min-height: 70vh;
	}
	&.small {
		min-height: 40vh;
	}
	.container {
		max-width: 50rem;
	}
	.hero--title {
		font-size: 4.5rem;
		@media (max-width: 48rem) {
			font-size: 2.5rem;
			line-height: 1.25;
		}
	}
	.hero--description {
		font-size: 2rem;
		line-height: 3rem;
		@media (max-width: 48rem) {
			font-size: 1.5rem;
			line-height: 2rem;
		}
	}
}
