@import '../../assets/styles/colors';
.AdminKids {
	.accordion-item {
		border: none;
	}
	.add-child-button {
		padding: 1rem;
		button {
			display: block;
			text-align: center;
			background-color: transparent;
			box-shadow: none;
			color: $white;
			&:after {
				background-image: none;
			}
		}
	}
	.accordion-body {
		background-color: $primary-bright;
	}
}
