@import '../../assets/styles/colors';
.HeaderNav {
	background-color: $white;
	margin-bottom: 0;
	padding: 0.5rem 0;
	box-shadow: 0px 1rem 1.5rem -1.5rem $primary;
	z-index: 10;
	.headernav--logo {
		max-width: 6rem;
	}
	.hamburger-menu {
		color: $primary;
		background-color: transparent;
		outline: none;
		border: none;
	}
	nav {
		align-items: center;
		ul {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			margin: 0;
			list-style: none;
			li {
				a {
					padding: 0.5rem 1rem;
					background-color: transparent;
					color: $gray;
					font-weight: bold;
					text-decoration: none;
					&:hover,
					&.active {
						color: $primary-hover;
					}
				}
			}
		}
	}
}
