@import '../../assets/styles/colors';
.Input {
	width: 100%;
	margin-bottom: 1rem;
	transition: 0.3s;
	&:focus-within {
		.label {
			color: $primary;
		}
		input {
			border-color: $primary;
		}
	} 
	.label {
		font-weight: bold;
		color: $gray;
		margin-bottom: 0.5rem;
		display: block;
		text-align: left;
		transition: 0.3s;
	}
	input {
		outline: none;
		width: 100%;
		padding: 1rem;
		border: solid 2px transparent;
		border-radius: 0.25rem;
		appearance: none;
		color: $gray-dark;
		background-color: $input-bright;
		transition: 0.3s;
		&::placeholder {
			color: $gray-light;
		}
		&:disabled {
			opacity: 0.5;
			pointer-events: none;
		}	
	}
	.description {
		font-size: 0.75rem;
		line-height: 1.25;
		margin-top: 0.5rem;
		margin-bottom: 0;
	}
}
