.Contact {
  .Hero {
    background-image: url(../../assets/images/contact_bg.svg);
    background-size: cover;
    background-position: center;
  }
  .contact-form {
    max-width: 31rem;
    margin: 0 auto;
  }
  }