@import '../../assets/styles/colors';
.Radio {
    color: $primary-bright;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    &:hover {
        input ~ .radio--element {
            &::before {
                background: $primary-light;
                transform: scale(0.7);
                display: block;
                border-radius: 50%;
            }
            // background: $gray;
        }
    }
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked ~ .radio--element {
            &::before {
                transform: scale(1);
                background: $primary;
            }
            &::after {
                display: block;
                width: 0.65rem;
                height: 1rem;
                left: 50%;
                top: 50%;
                border: solid white;
                border-width: 0 0.25rem 0.25rem 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: translate(-50%, -60%) rotate(45deg);
            }
        }
        & ~ .radio--element {
            position: absolute;
            height: 2rem;
            width: 2rem;
            border-radius: 50%;
            background: $primary-bright;
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 2rem;
                width: 2rem;
                border-radius: 50%;
                transform: scale(0);
                transition: all 0.3s;
            }
            &::after {
                content: '';
                position: absolute;
                display: none;
                
                transition: all 0.3s;
            }
        }
        
    }
    .label {
        margin-left: 2.5rem;
        font-weight: bold;
        color: $gray;
        display: block;
        text-align: left;
    }
}