@import '../../assets/styles/colors';
.CreateAccount {
    .form-side {
        background: $primary;
        min-height: calc(100vh - 109px);
        @media (max-width: 650px) {
          min-height: calc(90vh - 129px);
        }
      }
      .photo-side {
        background-image: url(../../assets/images/create-account-photo.jpg);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 100%;
        @media (max-width: 650px) {
          height: 300px;
        }
      }
}