@import '../../assets/styles/colors';
.HelpMessage {
    hr {
        margin: 1.5rem 0;
        border-color: $primary-bright;
    }
    .HelpMessageBox {
        border-radius: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        svg {
            fill: $primary;
        }
        p {
            color: $gray;
        }
    }
}
