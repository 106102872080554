@import '../../assets/styles/colors';
.FloatingDashButtonForm {
	z-index: 10;
	bottom: 2rem;
	right: 2rem;
	@media (max-width: 48rem) {
		bottom: 0;
		right: 0rem;
	}
	position: fixed;
	> button {
		height: 5rem;
		width: 5rem;
		display: flex;
		justify-content: center;
		align-items: center;
		border: none;
		outline: none;
		position: absolute;
		bottom: 0rem;
		right: 0rem;
		@media (max-width: 48rem) {
			bottom: 1rem;
			right: 1rem;
			height: 4rem;
			width: 4rem;
		}
		svg {
			path {
				color: $white;
			}
		}
		img {
			max-width: 2rem;
		}
	}
	.FloatingDashForm {
		width: 18rem;
		position: inherit;
		right: 2rem;
		bottom: 8rem;
		top: unset;
		box-shadow: 0 0 15px -5px rgb(0 0 150 / 50%);
		animation: fadeIn linear 0.3s forwards;
		@media (max-width: 48rem) {
			right: 1rem;
			bottom: 6rem;
			left: 1rem;
			width: unset;
		}
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
