@import '../../assets/styles/colors';
.Tasks {
    list-style: none;
    padding: 0;
    li.task {
      .round-card {
        padding: 20px 30px;
        background: white;
        border-radius: 30px;
        box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.1);
        margin: 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .task-name {
          a {
            font-size: 26px;
          }
          p {
            margin: 0;
            font-weight: bold;
          }
        }
        &.disabled {
          background: $gray !important;
          color: var(--primelt);
          opacity: 0.4;
          .task-name {
            a {
              color: var(--primelt);
            }
            p {
              color: var(--primelt);
            }
          }
        }
      }
    }
  }