@import '../../assets/styles/colors';
.KidCard {
	h3,
	p {
		margin: 0;
	}
	button {
		display: inline-block;
	}
	.edit-btn {
		width: 1.5rem;
		max-height: 1rem;
		float: right;
	}
}
