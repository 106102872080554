@import '../../assets/styles/colors';
.CompletedTasksList {
	padding-top: 2rem;
	list-style: none;
	@media (max-width: 48rem) {
		padding: 0;
	}
	li {
		font-weight: bold;
		padding: 1rem 0;
		border-bottom: $gray-bright solid 1px;
		&:last-child {
			border-bottom: none;
		}
	}
}
